import * as React from "react";
import Layout from '../../components/Layout'
import {MainText} from "../../components/MainComponents";
import {
    BottomGridContainer,
    BottomText,
    CompanyInfo,
    ContactPoint,
    Container,
    FieldDescription,
    FilipPhoto,
    FilipText,
    FilipTextName,
    FilipTextUnder,
    MainSection,
    MiddleGridContainer,
    TopPhotoFrame,
    TunaSecPhoto
} from "../../components/Contact/layout";
import ContactForm from "../../components/Contact/ContactForm";
import Helmet from "react-helmet";

export default () => {
    const title = "Ozvěte se nám";

    return (
        <Layout>
            <Helmet titleTemplate={`Kontakt na nás - ptej se, rádi odpovíme | TunaSec`}>
                {/* General tags */}
                <title>Kontakt na nás - ptej se, rádi odpovíme | TunaSec</title>
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Kontakt na nás - ptej se, rádi odpovíme | TunaSec"/>
                <meta name="keywords" content="bezpečnost, it security, it bezpečnost" />
                <meta name="description" content="Potřebuješ nám napsat, zavolat nebo hledáš adresu či údaje k fakturaci? Všechny informace o nás z TunaSec najdeš na této stránce." />
                <meta name="og:description" content="Potřebuješ nám napsat, zavolat nebo hledáš adresu či údaje k fakturaci? Všechny informace o nás z TunaSec najdeš na této stránce." />
                <meta name="og:image" content="https://tunasec.com/img/og-images/kontakt.png" />
            </Helmet>
            <TopPhotoFrame className="full-width-image margin-top-0">
                {/*<TopPhotoText>*/}
                    <MainText>{title}</MainText>
                {/*</TopPhotoText>*/}
            </TopPhotoFrame>
            <MainSection className="section section--gradient container">
                <Container>
                    <ContactPoint><a href="mailto:info@tunasec.com">info@tunasec.com</a></ContactPoint>
                    <ContactPoint><a href="tel:+420774071231">+420 774 071 231</a></ContactPoint>
                </Container>
                <MiddleGridContainer>
                    <FilipPhoto src={"/img/avatars/fifo_red.jpg"} alt={"Filip Holec foto"}/>
                    <FilipText>
                        <FilipTextName>Filip Holec</FilipTextName>
                        <FilipTextUnder>Potřebujete probrat spolupráci nebo s něčím pomoct?</FilipTextUnder>
                        <BottomText>E: <a href="mailto:info@tunasec.com">info@tunasec.com</a> | T: <a href="tel:+420774071231">+420 774 071 231</a></BottomText>
                    </FilipText>
                </MiddleGridContainer>
                <TunaSecPhoto src={"/img/tunalogo.webp"} alt={"TunaSec logo"}/>
                <BottomGridContainer>
                    <CompanyInfo>
                        <FieldDescription>Sídlo a fakturační údaje</FieldDescription>
                        <strong>TunaSec z.s.</strong><br/>
                        Moldavská 527/3<br/>
                        625 00 Brno<br/><br/>
                        IČO: 09453555<br/>
                        DIČ: CZ09453555<br/>
                    </CompanyInfo>
                    <CompanyInfo>
                        <FieldDescription>Bankovní spojení</FieldDescription>
                        Účet vedený u Raiffeisenbank a.s.<br/>
                        Hvězdova 1716/2b, 140 78 Praha 4<br/><br/>
                        Číslo účtu: 2954235002/5500<br/>
                        IBAN: CZ6655000000002954235002<br/>
                        SWIFT: RZBCCZPP<br/>
                    </CompanyInfo>
                    <ContactForm/>
                </BottomGridContainer>
                <div style={{marginBottom: "50px"}}/>
                <FieldDescription>Rezervace konzultace</FieldDescription>
                <iframe src="https://koalendar.com/e/konzultace-s-filipem-z-tunasec?embed=true" width="100%"
                        height="700px" frameBorder="0"/>
            </MainSection>
        </Layout>
    );
}
