import {useFormik} from "formik";
import * as React from "react";
import styled from "styled-components";
import emailjs from 'emailjs-com';
import {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

const UserID = "user_ePF49CR4XZ8HXToUxz3tN"
const ServiceID = "service_fb6yz8f"

const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 10px;
`

const MainForm = styled.form`
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  background-color: #F0F0F0;
`

const SubmittedForm = styled.div`
  border-radius: 6px;
  background-color: #F0F0F0;
  min-height: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
`

const SubmittedHeading = styled(Heading)`
  text-align: center;
  margin: auto;
`

const InputField = styled.input`
  width: 96%;
  height: 32px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
`

const TextArea = styled.textarea`
  width: 96%;
  min-height: 100px;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
`

const SumbitButton = styled.button`
  padding: 10px;
  font-weight: bold;
`

const Description = styled.div`
  margin: 10px;
  text-align: left;
  font-size: 18px;
`

export default () => {
    const form = useRef();
    const [submitted, setSubmit] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();


    };


    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            message: '',
            phone: '',

        },
        onSubmit: values => {
            const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if(values.name.length < 5) {
                alert("Jméno musí mít nejmíň 5 znaků")
                return
            }
            if(!emailRegexp.test(values.email)) {
                alert("Je nutné zadat validný email")
                return
            }
            if(values.message.length < 10) {
                alert("Zpráva musí mít nejmíň 10 znaků")
                return
            }
            emailjs.sendForm(ServiceID, 'contact-form', form.current, UserID)
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
            });
            setSubmit(!submitted);
        },
    });

    if(submitted) {
        return (
            <div>
                <SubmittedForm>
                    <SubmittedHeading><FontAwesomeIcon icon={faCheck} color={"green"}/> <br/> Zpráva odeslána, děkujeme!</SubmittedHeading>
                </SubmittedForm>
            </div>
        )
    }

    return (
        <div>
            <MainForm ref={form} onSubmit={formik.handleSubmit} id="contact-form">
                <Heading>Napište nám</Heading>
                <Description>Máte na nás otázku? Kontaktovat nás můžete taky prostřednictvím tohoto formuláře.</Description>
                <InputField
                    placeholder={"Jméno"}
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <InputField
                    placeholder={"E-mail"}
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                <InputField
                    placeholder={"Telefón (nepovinné)"}
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />
                <TextArea
                    placeholder={"Vzkaz pro nás"}
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    />
                <SumbitButton type="submit">ODESLAT</SumbitButton>
            </MainForm>
        </div>
    );
}