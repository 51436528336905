import styled from "styled-components";
import {media} from "../variables";

export const TopPhotoFrame = styled.div`
  background-image: url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
  background-position: top left;
  background-size: 1920px;
  height: 350px;
`

export const TopPhotoText = styled.div`
  display: flex;
  height: 150px;
  line-height: 1;
  justify-content: space-around;
  flex-direction: column;
`

export const MainSection = styled.section`
  min-height: 60vh;
  margin-bottom: 60px;
`

export const Container = styled.div`
  min-height: 180px;
  text-align: center;
  margin: auto auto;

  ${media.tablet`
    min-height: 150px;
  `}
`

export const MiddleGridContainer = styled(Container)`
  display: grid;
  grid-template-columns: 210px max-content;
  margin-bottom: 40px;
  margin-left: 30px;

  ${media.tablet`
    display: block;
    margin-left: 0;
  `}
`

export const ContactPoint = styled.p`
  font-size: 32px;
  font-weight: bold;
  text-decoration: underline;

  ${media.tablet`
    font-size: 28px;
  `}
`

export const FilipPhoto = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-left: 40px;
  
  ${media.tablet`
    width: 90px;
    height: 90px;
    margin-left: 0px;
  `}
`

export const TunaSecPhoto = styled.img`
  width: 220px;
  ${media.mobile`
    width: 160px;
  `}
`

export const BottomGridContainer = styled.div`
  display: grid;
  grid-template-columns: 28% 32% 40%;
  
  ${media.mobile`
    display: block;
  `}
`

export const FilipText = styled.div`
  margin-top: 40px;
  font-size: 24px;
  text-align: left;

  ${media.tablet`
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  `}
`

export const FilipTextName = styled.p`
  font-weight: bold;
  ${media.tablet`
      text-align: center;
  `}
`

export const FilipTextUnder = styled(FilipText)`
  margin-top: 0;
  ${media.tablet`
    font-size: 18px;
  `}
`

export const BottomText = styled.div`
    font-size: 18px;
  ${media.mobile`
    font-size: 16px;
  `}
`

export const CompanyInfo = styled.div`
  margin-top: 20px;
  font-size: 18px;
  ${media.tablet`
    font-size: 16px;
  `}
  ${media.mobile`
    margin-bottom: 30px;
    font-size: 18px;
  `}
`

export const FieldDescription = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`